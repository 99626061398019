import {
    gsap
} from "gsap"

export default class Preloader {
    constructor() {
        this.container = document.querySelector("#preloader")
        this.loaded = false
        this.location_to = null
        this.setTL()
    }

    setTL() {
        this.tl = gsap.timeline({
            paused: true,
            onReverseComplete: () => {
                if (this.location_to) {
                    window.location.href = this.location_to
                }
            }
        }).to(this.container.querySelector("img"), {
            ease: 'power2.out',
            duration: .45,
            y: -5
        }, 0).to(this.container, {
            ease: 'power2.out',
            duration: .45,
            display: 'none',
            opacity: 0
        }, 0).to("main", {
            // force3D: true,
            ease: 'power2.out',
            duration: .45,
            opacity: 1
            // y: 0
        }, .1)
    }

    show(_to = false) {
        if (!this.loaded) return false
        this.loaded = false
        if (_to) this.location_to = _to
        return this.tl.duration(.45).reverse()
    }

    hide() {
        if (this.loaded) return false
        this.loaded = true
        return this.tl.delay(3).play()
    }
}